/**
 * Concert Event
 */

.concert-event
  .event-info
    line-height: 2em
    margin-bottom: $spacing-unit
  .event-item
    color: #000
    margin: 5px 0
    font-weight: bold

    &.event-youtube
      a
        color: $color-red
        &:hover
          color: lighten($color-red, 20%)

    &.event-ticket
      margin-top: $spacing-unit/2

  p.quote-author
    text-align: right
    margin-top: -10px
    margin-bottom: 30px

