/**
 * Page content
 */

.page

.page-content


.page-title
  position: relative
  z-index: 1

.page-img
  position: relative
  img
    max-width: 66%

  &.page-img-heading
    img
      max-width: 100%
      width: 100%

.page
  .image_side
    max-width: 100%

.page-section
  margin-top: $spacing-unit*2

  &.page-years
    margin-top: $spacing-unit*4

.page-subsection
  margin-top: $spacing-unit

.page-comments
    margin-top: $spacing-unit*2

.page-caption
  font-size: 0.875em

.page-label
  position: relative
  font-size: 0.875em
  padding-right: 50%
  text-transform: uppercase
  line-height: 1em
  margin-bottom: $spacing-unit
  font-weight: bold

  &:after
    content: ""
    position: absolute
    display: block
    right: 0
    top: 0
    width: 50%
    border-top: 1px solid $color-border

@include media-query($mobile-break)
  .page
    width: 100%

    .page-section

      &:first-child
        margin-top: 0

      &.page-years
        margin-top: $spacing-unit

    .page-img
      img
        max-width: 100%
    .page-content
      padding: 0
    .page-label
      margin-bottom: $spacing-unit/2

      &:after
        width: 100%
        top: auto
        bottom: $spacing-unit
    .page-caption
      margin-bottom: $spacing-unit/2

      

