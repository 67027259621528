@charset "utf-8"

// Define defaults for each variable.

$base-font-family: Helvetica, Arial, sans-serif
$base-font-size:   14px !default
$base-font-weight: 400 !default
$small-font-size:  $base-font-size * 0.875 !default
$base-line-height: 1.7 !default

$alternative-font-family: MyWriting, serif
$heading-font-weight: 600 !default


$spacing-unit:     30px !default

$color-background: #FFFFFF !default
$color-background-dark: #C9CACB !default
$color-blue:       #0098BD !default
$color-green:      #ADD036 !default
$color-yellow:     #F9C30C !default
$color-red:		   #FF0000 !default
$color-black:      #000 !default
$color-grey:       #777777 !default
$text-color:       #777777 !default
$color-border: #CECECE !default

$animation-time: 0.15s !default

$table-text-align: left !default

// Width of the content area
$full-width:    1264px !default

// break at the width of the content area + some side margins (60px)
$full-width-break:          1304px !default

// break at the width of the content area + some side margins (60px)
$mobile:          984px !default

$mobile-break: 	  1024px !default

// Use media queries like this:
// @include media-query($on-palm) {
//   .wrapper {
//     padding-right: $spacing-unit / 2
//     padding-left: $spacing-unit / 2
//   }
// }
@mixin media-query($device)
  @media screen and (max-width: $device)
    @content

@mixin relative-font-size($ratio)
  font-size: $base-font-size * $ratio 


// Import partials.
@import "fonts"
@import "base"
@import "grid"
@import "button"
@import "tooltip"
@import "card"
@import "icon"
@import "header"
@import "footer"
@import "event"
@import "page"
@import "post"
@import "syntax-highlighting"
