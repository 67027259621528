.grid
	column-count: 3
	column-gap: $spacing-unit*2

.card
	break-inside: avoid
	margin-bottom: $spacing-unit
	
	.card-content
	.card-img
		width: 100%
	.card-fb
		margin-top: $spacing-unit

.card-links
	display: inline-block
	text-align: center
	a
		display: inline-block
		color: $text-color
		font-size: 12px
		margin-left: $spacing-unit/2

	img
		margin-bottom: $spacing-unit/2



@include media-query($mobile-break)
	.grid
		column-count: 1
		column-gap: 0

	.card
		max-width: 100%
		width: 100%
		padding: $spacing-unit 0



