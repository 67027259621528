/**
 * Site footer
 */
.site-footer
  padding: 120px 0 60px
  min-height: 50px
  width: $full-width
  margin: 0 auto


  .footer-logos
    display: flex
    justify-content: space-between
  .footer-logo
    height: 55px

  .footer-links
    margin-top: $spacing-unit*2
    text-align: right

    a
      margin-left: 1em

  .footer-attrib
    font-size: 0.85em
    margin-top: -30px

@include media-query($full-width-break)
  .site-footer
    width: $mobile
    .footer-logo
      height: 45px


@include media-query($mobile-break)
  .site-footer
    width: 100%
    padding: $spacing-unit
    box-sizing: border-box

    .footer-logo
      width: 75%
      height: auto

    .footer-links
      margin-top: $spacing-unit
      font-size: 0.875em
      text-align: left
