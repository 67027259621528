/**
 * Posts
 */
.post-list
  margin-left: 0
  padding-top: $spacing-unit
  li
    list-style-type: none
    display: flex

  .post-title
    flex: 1

  .post-meta
    width: 80px
    font-size: 12px

  .post-content
    flex: 1