// Button
// ––––––––––––––––––––––––––––––––––––––––––––––––––

.button,
button,
input[type='button'],
input[type='reset'],
input[type='submit']
	border: 1px solid $color-blue
	border-radius: .4rem
	color: $color-blue
	cursor: pointer
	display: inline-flex
	font-size: 0.8rem
	padding: 0.3rem 1rem
	font-weight: bold
	line-height: 1.2rem
	text-align: center
	text-decoration: none
	white-space: nowrap
	transition: all 0.2s ease

	&:focus,
	&:hover
		background-color: $color-blue
		color: #FFF
		outline: 0

	&[disabled]
		cursor: default
		opacity: .5

		&:focus,
		&:hover
			background-color: $color-blue
			border-color: $color-blue
