/**
 * Site header
 */
.site-header
  padding: $spacing-unit*3 0 $spacing-unit
  min-height: 50px
  width: $full-width
  margin: 0 auto
  display: flex
  position: relative
  align-items: flex-end


.site-logo img
  width: 187px
  height: 83px


.site-nav
  flex: 1
  text-transform: uppercase
  margin: 0
  font-family: $alternative-font-family
  letter-spacing: 1.5px
  text-align: right

  .page-link
    color: #000
    line-height: $base-line-height
    margin-left: $spacing-unit
    transition: color $animation-time

    &.page-member
      border-bottom: 1px solid $color-blue

    &.page-ensemble
      border-bottom: 1px solid $color-green

    &.page-concerts
      border-bottom: 1px solid $color-yellow

    &.donate
      padding: 7px
      border-radius: 7px
      background-color: $color-blue
      color: #FFF

    &:hover, &.active
      &.page-member
        color: $color-blue
      &.page-ensemble
        color: $color-green
      &.page-concerts
        color: $color-yellow


@include media-query($full-width-break)
  .site-header
    width: $mobile

@include media-query($mobile-break)
  .site-header
    width: 100%
    display: block
    padding-top: 0
    text-align: center

    .site-logo
      text-align: center
      img
        margin: $spacing-unit 0


    .site-nav
      text-align: center
      margin-left: 0
      margin-top: $spacing-unit/2
      .page-link
        margin-left: $spacing-unit/2
        margin-right: $spacing-unit/2


    .eth-logo
      position: absolute
      top: $spacing-unit
      right: $spacing-unit

